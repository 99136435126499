:root {
  /* START SPACING */
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  /* END SPACING */

  /* START COLORS */
  --primary-color: #2A76D2;
  --brand-main-color: #34A69D;
  
  /* --primary-color: #f48220; */
  
  --secondary-color: #83878a;

  --green: rgb(170, 227, 194);
  --light-green: rgba(170, 227, 194, 0.7);
  --dark-green: #246d4a;

  --blue: rgb(127, 179, 213);;
  --light-blue: rgba(127, 179, 213, 0.7);
  --dark-blue: #2a5885;

  --purple: rgb(191, 160, 255);
  --light-purple: rgba(191, 160, 255, 0.7);
  --dark-purple: #5C3B9E;

  --grey: rgb(224, 224, 224);
  --light-grey: rgba(224, 224, 224, 0.7);
  --dark-grey: #555555;

  --light-primary-green: rgba(17, 164, 154, 0.7);
  --dark-primary-green: rgba(17, 164, 154, 1);

  --body-dark-bg: #0f1214;
  --body-light-bg: #eeeeee;

  /* END COLORS */

  /* START CARDS */
  --card-spacing-1: var(--spacing-1);
  --card-spacing-2: var(--spacing-2);
  --card-spacing-3: var(--spacing-3);
  --card-spacing-4: var(--spacing-4);
  --card-spacing-5: var(--spacing-5);

  --card-radius-1: var(--spacing-1);
  --card-radius-2: var(--spacing-2);
  --card-radius-3: var(--spacing-3);
  --card-radius-4: var(--spacing-4);
  --card-radius-5: var(--spacing-5);

  --card-background: white;
  --card-dark-background: #2c2c2c;

  --card-footer-background: #f8f8f8;

  --card-border-color: #dfdfdf;
  /* END CARDS */

  /* START HEADER */
  --header-spacing-1: var(--spacing-1);
  --header-spacing-2: var(--spacing-2);
  --header-spacing-3: var(--spacing-3);
  --header-spacing-4: var(--spacing-4);
  --header-spacing-5: var(--spacing-5);

  --header-height: 80px;

  --header-background: white;
  /* END HEADER */

  /* START SHADOWS */
  --shadow-15: rgba(0, 0, 0, 0.15);
  --shadow-20: rgba(0, 0, 0, 0.2);
  --shadow-25: rgba(0, 0, 0, 0.25);
  --shadow-30: rgba(0, 0, 0, 0.30);
  /* END SHADOWS */
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-light-bg) !important;
}

.App {
  max-width: 65%;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.pagination .active.item {
  background-color: var(--primary-color) !important;
  color: white !important;
}

i {
  line-height: 1 !important;
}

.filter-btn-active {
  background-color: white !important;
  color: var(--secondary-color) !important;
}

.filter-active {
  height: auto !important;
  overflow: unset !important;
}

.privacy-policy-page {
  padding: var(--spacing-12) 0;
}

.privacy-policy-page *:not(a) {
  /* color: white !important; */
}

#paginationBox li button[aria-current="true"] {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

#paginationBox li button:not([aria-current="true"]):hover {
  background-color: rgba(144, 202, 249, 0.5);
  color: var(--secondary-color);
}

figure img {
  max-width: 100% !important;
}

.wp-block-heading {
  margin: 0;
}

@media only screen and (max-width: 1080px) {
  .App {
    max-width: 80%;
  }
}